!function (e) {
    e.module("ngTableToCsv.config", []).config(["$compileProvider", function (e) {
        e.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|data):/)
    }]).value("ngTableToCsv.config", {debug: !0}), e.module("ngTableToCsv.directives", []), e.module("ngTableToCsv", ["ngTableToCsv.config", "ngTableToCsv.directives"])
}(angular), function (e) {
    // "use strict";
    e.module("ngTableToCsv.directives").directive("exportCsv", ["$parse", function (n) {
        return {
            restrict: "A", scope: !1, link: function (t, i, a) {
                var r = "", o = a.separator ? a.separator : ",", s = {
                    stringify: function (e) {
                        return '"' + e.replace(/^\s\s*/, "").replace(/\s*\s$/, "").replace(/"/g, '""') + '"'
                    }, generate: function () {
                        r = "";
                        var n = i.find("tr");
                        e.forEach(n, function (n) {
                            var t = e.element(n), i = t.find("th"), a = "";
                            t.hasClass("ng-table-filters") || (0 === i.length && (i = t.find("td")), e.forEach(i, function (n) {
                                if (!(e.element(n).hasClass("ignore")) && !(e.element(n).text().split(' ').join('') == "Update")) {
                                    a += s.stringify(e.element(n).text()) + o
                                }
                            }), a = a.slice(0, a.length - 1), r += a + "\n")
                        })
                    }, link: function () {
                        return "data:text/csv;charset=UTF-8," + encodeURIComponent(r)
                    }
                };
                n(a.exportCsv).assign(t.$parent, s)
            }
        }
    }])
}(angular);
