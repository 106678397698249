require('angular-material/angular-material.css');
require('angular-material-datetimepicker/css/material-datetimepicker.css');
require('angular-ui-grid/ui-grid.min.css');

require('../../../../static/assets/lib/kh_table/kh_table.css');

require('../../../../static/assets/lib/ng-table/dist/ng-table-csv.js');
require('../../../../static/assets/lib/ng-table/dist/kh-ng-table.js');
require('../../../../static/assets/lib/html2canvas/html2canvas.js');
require('../../../../static/assets/lib/kh_basic_utils.js');

// TODO: At somepoint in future we need to convert this into core angular component
require('../../../../static/assets/lib/angular-fix-image-orientation/angular-fix-image-orientation.js');

require('../../../../static/assets/lib/leaflet_libs/leaflet-map.html');
require.context('../../../../static/assets/lib/leaflet_libs/leaflet/images/', true);
require('../../../../static/assets/lib/leaflet_libs/setimagepath.js');
require('../../../../static/assets/lib/leaflet_libs/draw/leaflet.draw.js');
require('../../../../static/assets/lib/leaflet_libs/oms/oms.js');
require('../../../../static/assets/lib/leaflet_libs/leaflet_google/leaflet-google.js');
require('../../../../static/assets/lib/leaflet_libs/markercluster/leaflet.markercluster.js');
require('../../../../static/assets/lib/leaflet_libs/awesome_markers/leaflet.awesome-markers.min.js');
require('../../../../static/assets/lib/leaflet_libs/pointinpolygon/leaflet-pip.min.js');

require('../../../../static/assets/lib/leaflet_libs/leaflet_bottombar/L.Control.Bottombar.js');

require('../../../../static/assets/lib/leaflet_libs/leaflet_mouse_position/L.Control.MousePosition.js');

require('../../../../static/assets/lib/leaflet_libs/leafletsnap/leaflet.snap.js');
require('../../../../static/assets/lib/leaflet_libs/leaflet_zoom_box/L.Control.Boxzoom.js');
require('../../../../static/assets/lib/leaflet_libs/leaflet_opencage_search/js/L.Control.OpenCageSearch.js');
require('../../../../static/assets/lib/leaflet_libs/leaflet_coordinates/Control.Coordinates.js');


require('../../../../static/assets/lib/leaflet_libs/leaflet_label/leaflet.label.js');

require('../../../../static/assets/lib/leaflet_libs/angular-leaflet/angular-leaflet.min.js');
require('../../../../static/assets/lib/leaflet_libs/angular-leaflet/angular-leaflet-directive.min.js');

// leaflet css files
require('../../../../static/assets/lib/leaflet_libs/css/leaflet.css');
require('../../../../static/assets/lib/leaflet_libs/styledlayer/styledlayercontrol.css');
require('../../../../static/assets/lib/leaflet_libs/draw/leaflet.draw.css');
require('../../../../static/assets/lib/leaflet_libs/markercluster/leaflet.markercluster.css');
require('../../../../static/assets/lib/leaflet_libs/map_sidebar_form.css');
require('../../../../static/assets/lib/leaflet_libs/markercluster/leaflet.markercluster.default.css');
require('../../../../static/assets/lib/leaflet_libs/geocoder/control.geocoder.css');
require('../../../../static/assets/lib/leaflet_libs/fullscreen/leaflet.fullscreen.css');
require('../../../../static/assets/lib/leaflet_libs/styledlayer/styledlayercontrol.css');
require('../../../../static/assets/lib/leaflet_libs/awesome_markers/leaflet.awesome-markers.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_label/leaflet.label.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_sidebar/L.Control.Sidebar.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_bottombar/L.Control.Bottombar.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_locate/L.Control.Locate.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_zoom_box/L.Control.Boxzoom.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_opencage_search/css/L.Control.OpenCageSearch.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_mouse_position/L.Control.MousePosition.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_coordinates/Control.Coordinates.css');
require('../../../../static/assets/lib/leaflet_libs/leaflet_label/leaflet.label.css');
require('../../../../static/assets/lib/leaflet_libs/css/leaflet-map.css');
